import * as React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Heading,
  Link
} from "@chakra-ui/react";

export const CollabTable = (props) => {

  return props.data?.length !== 0 ? (
    <Box
      overflowX="auto"
      overflowY="auto"
      maxW='80rem'
      borderColor={"brand.White"}
      borderWidth={"5px"}
      maxHeight="300px"
      className="my-box"
    >
      <Table size="sm" variant="mytable">
        <Thead position="sticky" top={0} bgColor="white">
          <Tr>
            <Th>Collaborator Email</Th>
            <Th>Owner Email</Th>
            <Th>Item ID</Th>
            <Th>Item Name</Th>
            <Th>Permission</Th>
            <Th>Collaborator Business</Th>
            <Th>Owner Business</Th>
            <Th>File/Folder Link</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.data?.map((prop, key) => (
            <Tr key={key}>
              <Td>{prop.collaborator_email}</Td>
              <Td>{prop.owner_email}</Td>
              <Td>{prop.item_id}</Td>
              <Td>{prop.item_name}</Td>
              <Td>{prop.permission}</Td>
              <Td>{prop.collaborator_business}</Td>
              <Td>{prop.owner_business}</Td>
              <Td><Link _hover={{color: "white", textDecoration: "underline"}} href={`${prop.folder_url}`}>{prop.folder_url}</Link></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  ):
  (<Heading color={'white'} size={'sm'}>You are not a collaborator on any items at risk</Heading>)
};
