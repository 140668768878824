import { apiConfig } from "./apiConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callAPI(accessToken,endpoint,email) {
console.log("Token"+accessToken)
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };
  console.log(apiConfig.graphMeEndpoint+`${endpoint}/${email}`)
  return fetch(apiConfig.graphMeEndpoint+`${endpoint}/${email}`, options)
    .then((response) => response.json())

    .catch((error) => console.log(error));
}