import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
  colors: {
    brand: {
      DarkIndigo: "#0C2340",
      Aqua: "#59CBE8",
      DarkGrey: "#333F48",
      LightGrey: "#DBE2E9",
      Blue: "#005EB8",
      White: "#FFFFFF",
    },
  },
  styles: {
    global: () => ({
      body: {
        bg: "brand.DarkIndigo",
      },
    }),
  },
  components: {
    Table: {
      variants: {
        mytable: {
          th: {
            background: "brand.White",
            color: "brand.DarkIndigo",
          },
          tr: {
            _odd: {
              background: "brand.Blue",
              Textcolor: "brand.DBE2E9",
            },
            _even: {
              background: "brand.DBE2E9",
            },
          },
          td: {
            color: "White",
            fontWeight: "bold",
            fontFamily: "Arial",
          },
        },
      },
    },
  },
});
