import React from 'react';

import {
  Text,
  Button,
  Box,
  Link,
} from "@chakra-ui/react";

export const PopUp = () => {
  return (
    <>
      <Box position="fixed" bottom="20px" right={["16px", "20px"]} zIndex={3}>
        <Button size={"lg"} rounded={"full"} bg={"brand.Blue"}>
          <Link href="https://ge.box.com/v/VernovaBoxSeparationFAQ" isExternal>
          <Box textColor={"white"}>
            <Text size={"xl"}>FAQs</Text>
          </Box>
          </Link>
        </Button>
      </Box>
    </>
  );
};
